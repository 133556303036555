<template>
    <div
        id="home"
        class="page-wrapper page-home"
        data-gsap-change-color="default"
        data-gsap-end-footer
    >
        <!-- Hero Text -->
        <header
            class="hero-text site-max-width"
            hero-transition-group
            data-gsap-change-color="default"
        >
            <div class="container" data-inview="fadeInUp" data-delay="100">
                <p class="big-text">
                    On rassemble de la
                    <router-link :to="{ name: 'Creativite-fr' }"><span class="img-word" data-img="/static/ph.jpg">création</span></router-link> <br />
                    qui n’a pas froid aux yeux, du <br />
                    <router-link :to="{ name: 'Contenu-fr' }"><span class="img-word" data-img="/static/ph2.jpg">contenu</span></router-link> stratégiquement
                    bien <br />
                    pensé et des
                    <router-link :to="{ name: 'Production-fr' }"><span class="img-word" data-img="/static/ph3.jpg">productions</span></router-link> agiles
                    <br />
                    de tout calibre.
                </p>

                <div class="float-img-wrap">
                    <div class="float-img"></div>
                </div>
            </div>
        </header>

        <!-- Video -->
        <div class="full-video" data-gsap-change-color="default" hero-transition-group>
            <div class="container">
                <div data-inview="revealRight" data-color="colorAccentLight" data-delay="300">
                    <img
                        class="main"
                        src="@/assets/imgs/home-reel.jpg"
                        alt="Montagnes russes"
                    />
                </div>
                <div class="content">
                    <a href="https://vimeo.com/706724663" class="glightbox" >
                        <h2 class="video-title big-text" data-inview="fadeInUp" data-delay="100">
                            Voyez de quoi <br />
                            on est capables.
                            <svg
                                class="play-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                width="75.347"
                                height="75.348"
                                viewBox="0 0.026 75.347 75.348"
                                data-inview="fadeInUp" data-delay="200"
                            >
                                <g>
                                    <path
                                        d="M30.733 56.192V18.54c0-.983.591-1.868 1.499-2.244a2.427 2.427 0 0 1 .788-.18c.047-.003.094-.005.14-.005a2.424 2.424 0 0 1 1.718.712L53.704 35.65a2.416 2.416 0 0 1 .664 1.239 2.425 2.425 0 0 1-.664 2.195L34.878 57.91a2.428 2.428 0 0 1-1.718.712 2.414 2.414 0 0 1-.163-.006 2.423 2.423 0 0 1-.765-.18 2.428 2.428 0 0 1-1.5-2.243Zm4.857-31.789v25.925l12.962-12.962L35.59 24.403Z"
                                        fill="#ceffb4"
                                        fill-rule="evenodd"
                                        data-name="Path 14"
                                    />
                                    <path
                                        d="M37.674-.474c20.85 0 37.673 16.824 37.673 37.674a37.553 37.553 0 0 1-37.673 37.674A37.483 37.483 0 0 1 .004 37.813 39.296 39.296 0 0 1 0 37.2 37.554 37.554 0 0 1 37.674-.474Zm0 70.496c18.063 0 32.72-14.655 32.72-32.822 0-18.063-14.657-32.72-32.72-32.72C19.51 4.48 4.851 19.137 4.851 37.2a32.773 32.773 0 0 0 32.823 32.822Z"
                                        fill="#ceffb4"
                                        fill-rule="evenodd"
                                        data-name="Path 13"
                                    />
                                </g>
                            </svg>
                        </h2>
                    </a>
                </div>
            </div>
        </div>

        <!-- Content -->
        <div class="content-text site-max-width" data-gsap-change-color="black" data-gsap-force-color="black" hero-transition-group>
            <div class="container">
                <div class="navigation">
                    <ul>
                        <li>
                            <a href="#zone-q" @click.prevent="gsapScrollTo('#zone-q')" class="link" title="01. Le Q"
                                >01. Le Q
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    viewBox="1045.927 3505.281 42.427 45.719"
                                >
                                    <path
                                        d="m1088.354 3529.786-2.377-2.377-17.13 17.13v-39.258h-3.352v39.318l-17.19-17.19-2.378 2.377 21.214 21.214 21.213-21.214Z"
                                        fill="#ceffb4"
                                        fill-rule="evenodd"
                                        data-name="Path 77"
                                    /></svg
                            ></a>
                        </li>
                        <li>
                            <a href="#zone-bureau" @click.prevent="gsapScrollTo('#zone-bureau')" class="link" title="02. Le Bureau créatif"
                                >02. Le Bureau créatif
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    viewBox="1045.927 3505.281 42.427 45.719"
                                >
                                    <path
                                        d="m1088.354 3529.786-2.377-2.377-17.13 17.13v-39.258h-3.352v39.318l-17.19-17.19-2.378 2.377 21.214 21.214 21.213-21.214Z"
                                        fill="#ceffb4"
                                        fill-rule="evenodd"
                                        data-name="Path 77"
                                    /></svg
                            ></a>
                        </li>
                        <li>
                            <a href="#zone-ecosysteme" @click.prevent="gsapScrollTo('#zone-ecosysteme')" class="link" title="03. L'écosystème"
                                >03. L'écosystème
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    viewBox="1045.927 3505.281 42.427 45.719"
                                >
                                    <path
                                        d="m1088.354 3529.786-2.377-2.377-17.13 17.13v-39.258h-3.352v39.318l-17.19-17.19-2.378 2.377 21.214 21.214 21.213-21.214Z"
                                        fill="#ceffb4"
                                        fill-rule="evenodd"
                                        data-name="Path 77"
                                    /></svg
                            ></a>
                        </li>
                        <li>
                            <a href="#zone-valeurs" @click.prevent="gsapScrollTo('#zone-valeurs')" class="link" title="04. Nos valeurs"
                                >04. Nos valeurs
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    viewBox="1045.927 3505.281 42.427 45.719"
                                >
                                    <path
                                        d="m1088.354 3529.786-2.377-2.377-17.13 17.13v-39.258h-3.352v39.318l-17.19-17.19-2.378 2.377 21.214 21.214 21.213-21.214Z"
                                        fill="#ceffb4"
                                        fill-rule="evenodd"
                                        data-name="Path 77"
                                    /></svg
                            ></a>
                        </li>
                        <li>
                            <a href="#equipe" @click.prevent="gsapScrollTo('#equipe')" class="link" title="05. Nos dirigeants"
                                >05. Nos dirigeants
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    viewBox="1045.927 3505.281 42.427 45.719"
                                >
                                    <path
                                        d="m1088.354 3529.786-2.377-2.377-17.13 17.13v-39.258h-3.352v39.318l-17.19-17.19-2.378 2.377 21.214 21.214 21.213-21.214Z"
                                        fill="#ceffb4"
                                        fill-rule="evenodd"
                                        data-name="Path 77"
                                    /></svg
                            ></a>
                        </li>
                        <li>
                            <a href="#departements" @click.prevent="gsapScrollTo('#departements')" class="link" title="06. Nos services"
                                >06. Nos services
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    viewBox="1045.927 3505.281 42.427 45.719"
                                >
                                    <path
                                        d="m1088.354 3529.786-2.377-2.377-17.13 17.13v-39.258h-3.352v39.318l-17.19-17.19-2.378 2.377 21.214 21.214 21.213-21.214Z"
                                        fill="#ceffb4"
                                        fill-rule="evenodd"
                                        data-name="Path 77"
                                    /></svg
                            ></a>
                        </li>
                        <li>
                            <a href="#logos" @click.prevent="gsapScrollTo('#logos')" class="link" title="07. Nos clients"
                                >07. Nos clients
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    viewBox="1045.927 3505.281 42.427 45.719"
                                >
                                    <path
                                        d="m1088.354 3529.786-2.377-2.377-17.13 17.13v-39.258h-3.352v39.318l-17.19-17.19-2.378 2.377 21.214 21.214 21.213-21.214Z"
                                        fill="#ceffb4"
                                        fill-rule="evenodd"
                                        data-name="Path 77"
                                    /></svg
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- Content -->
        <div id="zone-q" class="content-text site-max-width" data-gsap-change-color="black">
            <div class="container">
                <article>
                    <h3 class="small-title title" data-inview="fadeInUp" data-delay="100">Le Q.</h3>
                    <p class="regular-text text" data-inview="fadeInUp" data-delay="200">
                        Pourquoi un Q? Parce qu’on se pose des Questions. Avec un Q. On repense ce qu’on pensait déjà connaître. On redéfinit les règles établies. Et on bouscule le statut quo à tous les jours.
                        <br /><br />
                        On a aussi emprunté ce Q là au mot Québec. Le Québec et tout ce qui vient avec  : sa langue, ses codes, ses référents. On est issus d’une culture unique en son genre, et tout ce qu’on crée doit l’être tout autant.
                    </p>
                </article>
            </div>
        </div>

        <!-- Content -->
        <div id="zone-bureau" class="content-text site-max-width" data-gsap-change-color="black">
            <div class="container">
                <article>
                    <div class="cols">
                        <div class="left" data-inview="fadeInUp" data-delay="100">
                            <h3 class="small-title">Le bureau créatif</h3>
                        </div>
                        <div class="right" data-inview="fadeInUp" data-delay="200">
                            <p class="small-text right-text">
                                Qolab est un tout nouveau format d’agence. Notre agence intégrée nouveau genre s’est formée autour d’une équipe de spécialistes - d’abord de production, ensuite de contenu et finalement de création - avec pour double objectif de mieux nous servir et de faire profiter de notre expertise à une variété de marques et d’annonceurs avec lesquels on avait envie de collaborer.
                                <br/><br/>
                                On se qualifie de bureau créatif, parce que, au fond, on marie le meilleur des deux mondes. On est issus de l’écosystème solide et complet qu’est Québecor, mais avec toute l’ébullition, l’ambition et la créativité d’une <em>startup</em>. Après maintenant près de trois ans d’existence, on a déjà près de 140 employés et plusieurs clients d’importance.
                                <br/><br/>
                                Tout ça, plus une énorme volonté de rester indépendant et une tout aussi grande ambition qui n’est pas prête à cesser de croître.
                            </p>
                        </div>
                    </div>
                </article>
            </div>
        </div>

        <!-- Content -->
        <div
            id="zone-ecosysteme"
            class="content-text graph-wrap no-border site-max-width"
            data-gsap-change-color="black"
            data-gsap-trigger-graph-init
        >
            <div class="container" data-gsap-trigger-graph>
                <div class="cols">
                    <div class="left">
                        <p class="small-text float-text" data-inview="fadeInUp" data-delay="100">
                            Des possibilités de <br />
                            partenariats infinies <br />
                            grâce à notre accès <br />
                            aux multiples filiales <br />
                            de Québecor. <br />
                        </p>
                    </div>
                    <div class="right">
                        <!-- Graph -->
                        <div class="graph">
                            <img
                                class="placeholder"
                                src="@/assets/imgs/graph-placeholder.png"
                                alt=""
                            />

                            <div class="circles">
                                <div class="circle center focus">
                                    <Logo class="logo" />
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-plus"
                                        width="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>

                                <div :class="{ 'is-active': circle === 1 }" class="circle top-left" data-gsap-trigger-graph-0 @click.prevent="togglePopup(true, 1)">
                                    <Mels class="logo" /><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-plus"
                                        width="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>
                                <div :class="{ 'is-active': circle === 2 }" class="circle top-right" data-gsap-trigger-graph-0 @click.prevent="togglePopup(true, 2)">
                                    <Quebecor class="logo down" /><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-plus"
                                        width="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>
                                <div :class="{ 'is-active': circle === 3 }" class="circle bottom-right" data-gsap-trigger-graph-0 @click.prevent="togglePopup(true, 3)">
                                    <Tva class="logo" /><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-plus"
                                        width="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>
                                <div :class="{ 'is-active': circle === 4 }" class="circle bottom-left" data-gsap-trigger-graph-0 @click.prevent="togglePopup(true, 4)">
                                    <Gestev class="logo" /><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-plus"
                                        width="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mobile-row">
                    <div class="top" @click.prevent="togglePopup(true, 1)">
                        <Mels class="logo" />
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-plus"
                            width="24"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="12" y1="5" x2="12" y2="19" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                    </div>
                    <!-- <div class="bottom">
                        <p class="small-text text">
                            Agence de marketing numérique clés en main, Elmire a pour but de faire la promotion de marques, de produits ou de services par différents canaux et plateformes numériques. Notre partenariat avec Elmire nous permet de mettre à profit leur expertise Web pour aider nos clients souhaitant se démarquer sur la toile. Ils savent nous conseiller pour des programmations Web des plus optimales selon les meilleures pratiques en expérience utilisateur du milieu.
                        </p>
                    </div> -->
                </div>
                <div class="mobile-row">
                    <div class="top" @click.prevent="togglePopup(true, 2)">
                        <Quebecor class="logo adjust down" />
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-plus"
                            width="24"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="12" y1="5" x2="12" y2="19" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                    </div>
                    <!-- <div class="bottom">
                        <p class="small-text text">
                            Gestev est une firme spécialisée dans l’organisation et la gestion d’événements de toute envergure. C’est grâce à eux que nos idées plus expérientielles voient le jour. Leur expertise en activation terrain, en activation de commandite et en marketing événementiel nous sert chaque fois qu’on désire sortir du cadre. Ils nous offrent l’ensemble de leurs services de création, d’organisation, de mise en marché et de promotion.
                        </p>
                    </div> -->
                </div>
                <div class="mobile-row">
                    <div class="top" @click.prevent="togglePopup(true, 3)">
                        <Tva class="logo" />
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-plus"
                            width="24"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="12" y1="5" x2="12" y2="19" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                    </div>
                    <!-- <div class="bottom">
                        <p class="small-text text">
                            Reconnue mondialement pour ses studios de tournage et sa main-d’œuvre hautement qualifiée, MELS est l’une des plus importantes entreprises de services de cinéma et de télévision au Canada. Notre étroit partenariat avec MELS nous permet de réaliser nos plus grandes idées. Bien que notre équipe de production interne n’ait rien à envier à personne, on a souvent recours au soutien et à l’expertise de MELS, qui nous accompagne dans de nombreux projets.
                        </p>
                    </div> -->
                </div>
                <div class="mobile-row">
                    <div class="top" @click.prevent="togglePopup(true, 4)">
                        <Gestev class="logo" />
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-plus"
                            width="24"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="12" y1="5" x2="12" y2="19" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                    </div>
                    <!-- <div class="bottom">
                        <p class="small-text text">
                            Jeune firme de relations publiques composée d’experts expérimentés, Leliken offre principalement des services de relations gouvernementales et de relations publiques pour répondre aux besoins d’une clientèle variée. Avec leur aide, nous excellons à protéger le lien de confiance entre nos clients et partenaires ainsi que le monde entier. Ils sont notre bouclier du quotidien, au grand bénéfice de nos équipes.
                        </p>
                    </div> -->
                </div>
            </div>
        </div>

        <!-- Content -->
        <div
            id="zone-valeurs"
            class="content-text content-valeurs is-red no-border site-max-width"
            data-gsap-change-color="colorAccent1"
        >
            <div class="container">
                <div class="top" data-gsap-change-color="colorAccent1">
                    <h3 class="small-title">Ce que nous valorisons.</h3>
                </div>
                <div class="carousel carousel-valeurs" data-gsap-change-color="colorAccent1">
                    <div class="carousel-cell">
                        <div class="cols">
                            <div class="left">
                                <h4 class="big-title">Courage</h4>
                            </div>
                            <div class="right">
                                <p class="small-text text">
                                    Chez Qolab, on ne cherche pas la voie facile. Selon nous, la pire chose qui puisse arriver à une marque, c’est de passer inaperçu. Voilà pourquoi on s’efforce jour après jour d’explorer de nouvelles avenues. C’est ce qui nous permet d’être différents, de nous distinguer, de briller. Et, puisqu'on y croit vraiment, on a la volonté et la rigueur de défendre nos idées, d’aller cogner aux bonnes portes et de prendre les grands moyens pour réaliser de grandes choses.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-cell">
                        <div class="cols">
                            <div class="left">
                                <h4 class="big-title">Confiance</h4>
                            </div>
                            <div class="right">
                                <p class="small-text text">
                                    Ici, on mise sur une approche humaine. On travaille ensemble, on s’écoute et on se respecte, tout ça dans le but de bâtir des relations durables, fondées sur la réciprocité. Et comme on sait à quel point la confiance est précieuse, on ne fait pas juste la gagner : on la bâtit, on l’entretient et on la préserve. On a à cœur d’établir des partenariats de longue durée avec nos clients, nos collègues, et nos idées.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-cell">
                        <div class="cols">
                            <div class="left">
                                <h4 class="big-title">Humilité</h4>
                            </div>
                            <div class="right">
                                <p class="small-text text">
                                    Qolab, c’est avant tout une équipe. Une équipe forte des expertises diverses qui la composent. Une équipe qui valorise la multiplicité d’idées. Parce que c’est en tenant compte de tous les points de vue et en travaillant ensemble qu’on arrive à se surpasser, qu’on arrive à des solutions audacieuses. Donc, on s’écoute, on ose, on propose. En gros, on a troqué l’ego pour l’ouverture d’esprit.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-cell">
                        <div class="cols">
                            <div class="left">
                                <h4 class="big-title">Curiosité</h4>
                            </div>
                            <div class="right">
                                <p class="small-text text">
                                    Le plus important pour nous, c’est d’être animés par ce qu’on fait. Aimer notre travail, nos idées, nos équipes, nos clients. Et tout ça nous pousse à toujours rester en avant de la parade. À essayer des trucs nouveaux, juste parce que. À rester curieux, toujours à l’affût, par pur intérêt. À faire des pieds et des mains pour nos projets, parce qu’on y croit.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-cell">
                        <div class="cols">
                            <div class="left">
                                <h4 class="big-title">Collaboration</h4>
                            </div>
                            <div class="right">
                                <p class="small-text text">
                                    Pour ceux qui ne l’avaient pas encore compris… Qolab, c’est pour collaboration. On a tu vraiment besoin d’en dire plus?
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Content -->
        <div id="equipe" class="team-grid-wrap site-max-width" data-gsap-change-color="default">
            <div class="container">
                <div class="simple-headers" data-inview="fadeInUp" data-delay="100">
                    <h3 class="small-title">Nos dirigeants</h3>
                </div>
                <div class="grid team-grid">
                    <article class="item team-item" v-for="item in teamMembers" :key="item.name" data-inview="fadeInUp" :data-delay="200">
                        <img
                            :src="item.image"
                            :alt="'Image profile de ' + item.name"
                        />
                        <div class="content">
                            <p class="name">{{ item.name }}</p>
                            <p class="desc">{{ item.desc }}</p>
                        </div>
                    </article>
                </div>
                <div class="outro">
                    <p class="regular-text text" data-inview="fadeInUp" data-delay="200">
                        Ne vous en faites pas, ils sont plus <br />
                        sympathiques que photogéniques.
                    </p>
                </div>
            </div>
        </div>

        <!-- Content -->
        <div id="departements" class="departements site-max-width" data-gsap-change-color="default">
            <div class="container">
                <div class="top">
                    <h3 class="small-title" data-inview="fadeInUp" data-delay="300">
                        Nos départements <br/> biens dégourdis.
                    </h3>
                </div>
                <div class="grid departements-grid">
                    <article class="item departement-item item-left" data-gsap-reveal>
                        <router-link :to="{ 'name': 'Creativite-fr' }">
                            <div class="thumb">
                                <img src="@/assets/imgs/creativite.png" alt="Illustration créativité" />
                                <div class="title">
                                    <h4 class="regular-title">Créativité</h4>
                                </div>
                            </div>
                            <div class="content">
                                <p class="tiny-text">
                                    Trouver des idées qui vont interpeller et toucher les gens. Les faire rire, sourire ou pleurer, rien de moins.
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        viewBox="541.448 13441.5 21.552 20"
                                    >
                                        <path
                                            d="m553 13441.5-1.12 1.121 8.074 8.075h-18.506v1.58h18.535l-8.104 8.104 1.12 1.12 10-10-10-10Z"
                                            fill="#1e1e1e"
                                            fill-rule="evenodd"
                                            data-name="Path 62"
                                        />
                                    </svg>
                                </p>
                            </div>
                        </router-link>
                    </article>
                    <article class="item departement-item item-center" >
                        <router-link :to="{ 'name': 'Contenu-fr' }">
                            <div class="thumb">
                                <img src="@/assets/imgs/contenu.png" alt="Illustration créativité" />
                                <div class="title">
                                    <h4 class="regular-title">Contenu</h4>
                                </div>
                            </div>
                            <div class="content">
                                <p class="tiny-text">
                                    Qui dit création dit aussi sortir du cadre. Utiliser les nouveaux médias pour toujours être au bon endroit, au bon moment.
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        viewBox="541.448 13441.5 21.552 20"
                                    >
                                        <path
                                            d="m553 13441.5-1.12 1.121 8.074 8.075h-18.506v1.58h18.535l-8.104 8.104 1.12 1.12 10-10-10-10Z"
                                            fill="#1e1e1e"
                                            fill-rule="evenodd"
                                            data-name="Path 62"
                                        />
                                    </svg>
                                </p>
                            </div>
                        </router-link>
                    </article>
                    <article class="item departement-item item-right" data-gsap-reveal>
                        <router-link :to="{ 'name': 'Production-fr' }">
                            <div class="thumb">
                                <img src="@/assets/imgs/production.png" alt="Illustration créativité" />
                                <div class="title">
                                    <h4 class="regular-title">Production</h4>
                                </div>
                            </div>
                            <div class="content">
                                <p class="tiny-text">
                                    Orchestrer de grandes choses. On a toutes les ressources pour donner vie à nos idées, et les regarder grandir.
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        viewBox="541.448 13441.5 21.552 20"
                                    >
                                        <path
                                            d="m553 13441.5-1.12 1.121 8.074 8.075h-18.506v1.58h18.535l-8.104 8.104 1.12 1.12 10-10-10-10Z"
                                            fill="#1e1e1e"
                                            fill-rule="evenodd"
                                            data-name="Path 62"
                                        />
                                    </svg>
                                </p>
                            </div>
                        </router-link>
                    </article>
                </div>
            </div>
        </div>

        <!-- Content -->
        <div id="logos" class="logos site-max-width" data-gsap-change-color="default">
            <div class="container">
                <div class="top">
                    <h3 class="small-title" data-inview="fadeInUp" data-delay="100">
                        Quelques-uns des clients avec qui on qolabore au quotidien
                    </h3>
                </div>
                <div class="carousel carousel-logos" data-inview="fadeInUp" data-delay="200">
                    <div class="carousel-cell" v-for="(items, i) in logos" :key="i">
                        <div class="logos-grid">
                            <a
                                href="#"
                                @click.prevent.stop
                                class="logo-item"
                                target="_blank"
                                rel="noopener noreferrer"
                                v-for="(item, i) in items"
                                :key="i"
                                :class="{
                                    'adjust': item.includes('Fizz') || item.includes('CAA') || item.includes('RQRA') || item.includes('flg'),
                                    'adjust2':item.includes('Tourisme') || item.includes('Hubert'),
                                    'adjust3':item.includes('Consommateurs'),
                                }"
                            >
                                <img :src="'/static/clients/' + item" :alt="item.replace('.svg', '')" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Popup informations -->
        <div id="popup-informations" class="popup-informations" :class="{ 'is-active': popupIsActive }"  @click.prevent="togglePopup(false)">
            <div class="container"  @click.prevent.stop>
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-close" width="32" viewBox="0 0 24 24" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round" @click.prevent="togglePopup(false)">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
                <div class="cols" v-if="circle === 4">
                    <div class="left">
                        <Gestev class="logo" />
                    </div>
                    <div class="right">
                        <p class="small-text text">
                            Gestev est une firme spécialisée dans l’organisation et la gestion d’événements de toute envergure. C’est grâce à eux que nos idées plus expérientielles voient le jour. Leur expertise en activation terrain, en activation de commandite et en marketing événementiel nous sert chaque fois qu’on désire sortir du cadre. Ils nous offrent l’ensemble de leurs services de création, d’organisation, de mise en marché et de promotion.
                        </p>
                    </div>
                </div>
                <div class="cols" v-if="circle === 1">
                    <div class="left">
                        <Mels class="logo" />
                    </div>
                    <div class="right">
                        <p class="small-text text">
                            Reconnue mondialement pour ses studios de tournage et sa main-d’œuvre hautement qualifiée, MELS est l’une des plus importantes entreprises de services de cinéma et de télévision au Canada. Notre étroit partenariat avec MELS nous permet de réaliser nos plus grandes idées. Bien que notre équipe de production interne n’ait rien à envier à personne, on a souvent recours au soutien et à l’expertise de MELS, qui nous accompagne dans de nombreux projets.
                        </p>
                    </div>
                </div>
                <div class="cols" v-if="circle === 3">
                    <div class="left">
                        <Tva class="logo" />
                    </div>
                    <div class="right">
                        <p class="small-text text">
                            Grand télédiffuseur d’émissions de divertissement, d’information et d’affaires publiques, distributeur de produits audiovisuels et producteur de magazines, TVA est notre plus grand allié en matière de production de contenu. L’expertise de notre équipe provient directement des talents de ce géant du divertissement qui nous a tout enseigné et qui est toujours prêt à nous aider. Grâce à lui, les possibilités de placement de produit, de placement média, de commandite et de talents sont infinies.
                        </p>
                    </div>
                </div>
                <div class="cols" v-if="circle === 2">
                    <div class="left">
                        <Quebecor class="logo" />
                    </div>
                    <div class="right">
                        <p class="small-text text">
                            Chef de file canadien des télécommunications, du divertissement, des médias d’information et de la culture, Québecor est l’une des entreprises de communications intégrées les plus performantes de l’industrie. Ayant un accès direct à l’ensemble de ses ressources, que ce soit en affichage, en imprimé, en télévision ou en numérique, nous avons la possibilité de rejoindre la population québécoise partout, tout le temps, quel que soit le moyen pour diffuser nos messages les plus pertinents.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Footer Nav -->
        <router-link :to="{ name: 'Contenu-fr' }" class="full-video footer-nav" data-gsap-activate-footer>
            <div class="container">
                <img class="main" src="@/assets/imgs/accueil-footer-nav.jpg" alt="Célébration" />
                <div class="content">
                    <h2 class="video-title big-text" data-inview="fadeInUp" data-delay="100">
                        Plus capable de vous contenir? <br />
                        Découvrez ce qu’on fait en <br />
                        contenu ici.

                        <!-- creativite-footer.png -->

                        <!-- Quand on a une idée, on la fait. Découvrez notre machine de production. -->
                        <!-- Toutes nos productions partent de quelque part. Voyez ce qu’on fait en créa. -->
                        <!-- Pour tout savoir sur notre grand terrain de jeu, c’est ici. -->

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="70"
                            viewBox="139 15409.606 95.627 88.742"
                        >
                            <path
                                d="m190.256 15409.607-4.973 4.973 35.829 35.828H139v7.012h82.239l-35.956 35.956 4.973 4.973 44.37-44.371-44.37-44.371Z"
                                fill="#ceffb4"
                                fill-rule="evenodd"
                                data-name="Path 471"
                            />
                        </svg>
                    </h2>
                    <!-- svg -->
                </div>
            </div>
        </router-link>

        <!-- Footer Nav -->
        <Footer />
    </div>
</template>

<script>
// @ is an alias to /src
import Logo from '@/components/svgs/Logo.vue'
// import Elmire from '@/components/svgs/Elmire.vue'
import Quebecor from '@/components/svgs/Quebecor.vue'
import Tva from '@/components/svgs/Tva.vue'
// import Leliken from '@/components/svgs/Leliken.vue'
import Mels from '@/components/svgs/Mels.vue'
import Gestev from '@/components/svgs/Gestev.vue'
import Footer from '@/components/Footer.vue'
import { mapState, mapGetters } from 'vuex'

import GLightbox from 'glightbox'
const Flickity = require('flickity')
require('flickity-fade')

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

import { initInViewAnimations } from '@/plugins/InViewAnimations'

export default {
    name: 'Home',

    components: {
        Logo,
        Footer,
        // Elmire,
        Quebecor,
        Tva,
        // Leliken,
        Mels,
        Gestev,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        GLightbox()
                        initInViewAnimations()
                    }, 1)

                    setTimeout(() => {
                        this.carouselValeurs = new Flickity(
                            document.querySelector('.carousel-valeurs'),
                            {
                                cellAlign: 'center',
                                wrapAround: true,
                                lazyLoad: 1,
                                imagesLoaded: true,
                                prevNextButtons: true,
                                pageDots: true,
                                fade: true,
                            }
                        )
                        this.carouselLogos = new Flickity(document.querySelector('.carousel-logos'), {
                            cellAlign: 'center',
                            wrapAround: true,
                            lazyLoad: 1,
                            imagesLoaded: true,
                            prevNextButtons: true,
                            pageDots: true,
                        })

                        setTimeout(() => {
                            this.initGsapHero()
                            this.initGsapBgColors()
                            this.initGsapGraph()

                            this.gsapActivateFooter()

                            this.carouselValeurs.resize()
                            this.carouselLogos.resize()

                            setTimeout(() => {
                                ScrollTrigger.refresh()
                            }, 475)
                        }, 1000)
                    }, 1000)
                }
            },
            immediate: true,
        },
    },

    data() {
        return {
            carouselValeurs: null,
            carouselLogos: null,
            popupIsActive: false,
            circle: 'none',
            teamMembers: [
                {
                    image: '/static/team/sebastien.jpg',
                    name: 'Sébastien Viau',
                    desc: 'Directeur général',
                    speed: 100,
                },
                {
                    image: '/static/team/rejean.jpg',
                    name: 'Réjean Fortin',
                    desc: 'Directeur principal stratégie de marque',
                    speed: 200,
                },
                {
                    image: '/static/team/jocelyn.jpg',
                    name: 'Jocelyn Goyer',
                    desc: 'Directeur principal création',
                    speed: 400,
                },
                {
                    image: '/static/team/lucie.jpg',
                    name: 'Lucie Gauthier',
                    desc: 'Directrice principale service production commerciale',
                    speed: 400,
                },
                {
                    image: '/static/team/mylene.jpg',
                    name: 'Mylène Loiselle',
                    desc: 'Directrice principale stratégie de marque et de contenu',
                    speed: 100,
                },
                // {
                //     image: '/static/team/diane.jpg',
                //     name: 'Diane Gignac',
                //     desc: 'Directrice de production imprimée et numérique',
                //     speed: 200,
                // },
                {
                    image: '/static/team/audre-anne.jpg',
                    name: 'Andrée-Anne Gauthier',
                    desc: 'Directrice stratégie omnicanale',
                    speed: 300,
                },
                {
                    image: '/static/team/hugues.jpg',
                    name: 'Hugues Choquette',
                    desc: 'Directeur de création',
                    speed: 300,
                },
                {
                    image: '/static/team/chantal.jpg',
                    name: 'Chantal Joly',
                    desc: 'Directrice de création',
                    speed: 100,
                },
                {
                    image: '/static/team/meggie.jpg',
                    name: 'Meggie Lavoie',
                    desc: 'Directrice service-conseil',
                    speed: 200,
                },
                {
                    image: '/static/team/corinne.jpg',
                    name: 'Corinne Sevigny',
                    desc: 'Directrice service-conseil',
                    speed: 300,
                },
                {
                    image: '/static/team/noemie-t.jpg',
                    name: 'Noémie Tremblay',
                    desc: 'Directrice service-conseil',
                    speed: 400,
                },
                // {
                //     image: '/static/team/veronic.jpg',
                //     name: 'Véronic Cholette-Roberts',
                //     desc: 'Directrice média, Intercos',
                //     speed: 100,
                // },
                {
                    image: '/static/team/pascale.jpg',
                    name: 'Pascale Fregeau',
                    desc: 'Directrice service-conseil',
                    speed: 200,
                },
                {
                    image: '/static/team/laeticia.jpg',
                    name: 'Laetitia Metayer',
                    desc: 'Directrice, planification stratégique',
                    speed: 300,
                },
                {
                    image: '/static/team/juliette.jpg',
                    name: 'Juliette Ruer',
                    desc: 'Éditrice en chef',
                    speed: 400,
                },
                {
                    image: '/static/team/nour.jpg',
                    name: 'Nour Haik',
                    desc: 'Directrice de production',
                    speed: 200,
                },
                {
                    image: '/static/team/noemie-q.jpg',
                    name: 'Noemie Quilleré',
                    desc: 'Superviseure, stratégie et création de contenu',
                    speed: 200,
                },
                {
                    image: '/static/team/morgane.jpg',
                    name: 'Morgane Bourel',
                    desc: 'Superviseure, stratégie et création de contenu',
                    speed: 200,
                },
                {
                    image: '/static/team/audrey.jpg',
                    name: 'Audrey Desmeules',
                    desc: 'Productrice exécutive',
                    speed: 200,
                },
                {
                    image: '/static/team/ouiam.jpg',
                    name: 'Ouiam Didane',
                    desc: 'Superviseure, Contenus, Vidéotron-Fizz',
                    speed: 200,
                },
                {
                    image: '/static/team/genevieve.jpg',
                    name: 'Geneviève Cayer',
                    desc: 'Productrice exécutive',
                    speed: 200,
                },
            ],
            logos: [
                [
                    'CAA_Quebec_RGB_W_Fr.svg',
                    'desjardins.png',
                    'toyota.png',
                    'Metro_RGB_W.svg',
                    'flg.png',
                    'PnG_RGB_W.svg',
                    'St-Hubert_RGB_W.svg',
                    'bmr.png',
                    'Universite_de_Montreal_RGB_W.svg',
                    'Air_Inuit_RGB_W.svg',
                ],
                [
                    'Banque_Laurentienne_RGB_W_Fr.svg',
                    'Tourisme_Autochtone_Quebec_RGB_W_Fr.svg',
                    'nespresso.png',
                    'Option_Consommateurs_RGB_W.svg',
                    'Videotron_RGB_W_Fr.svg',
                    'Fizz_RGB_W.svg',
                    'Quebecor_RGB_W_Fr.svg',
                    'TVA_RGB_W.svg',
                    'mels.png'
                ]
            ]
        }
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        togglePopup(value, selectedCircle = 'none') {
            if (!value) {
                setTimeout(() => {
                    this.circle = 'none'
                }, 475)
            }
            if (selectedCircle !== 'none') {
                this.circle = selectedCircle
            }
            this.popupIsActive = value
        },
        initGsapHero() {
            const imgWords = gsap.utils.toArray('.img-word')
            const floatImgWrap = document.querySelector('.float-img-wrap')
            const floatImg = document.querySelector('.float-img')

            imgWords.forEach(link => {
                link.addEventListener('mouseenter', e => {
                    const targetImage = e.target.dataset.img
                    const t1 = gsap.timeline()
                    t1.set(floatImg, {
                        backgroundImage: `url(${targetImage})`,
                    }).to(floatImgWrap, {
                        duration: 0.5,
                        autoAlpha: 1,
                    })
                })
                link.addEventListener('mouseleave', () => {
                    const tl = gsap.timeline()
                    tl.to(floatImgWrap, {
                        duration: 0.5,
                        autoAlpha: 0,
                    })
                })
                link.addEventListener('mousemove', e => {
                    let xpos = e.clientX
                    let ypos = e.clientY
                    const tl = gsap.timeline()
                    tl.to(floatImgWrap, {
                        x: xpos,
                        y: ypos,
                    })
                })
            })
        },
        initGsapBgColors() {
            const classes = [
                'default',
                'dark',
                'black',
                'light',
                'colorAccent1',
                'colorAccent2',
                'colorAccent3',
                'colorAccent4',
                'colorAccent5',
            ]
            gsap.utils.toArray('[data-gsap-change-color]').forEach(block => {
                ScrollTrigger.create({
                    trigger: block,
                    scrub: 1.475,
                    start: 'top 75%',
                    end: 'bottom 30%',
                    onEnter: () => {
                        if (!document.querySelector('.page-wrapper.page-home')) {
                            return;
                        }
                        // console.log(block, block.dataset.gsapChangeColor)
                        classes.forEach(className => {
                            document.querySelector('.page-wrapper.page-home').classList.remove(className)
                        })
                        document.querySelector('.page-wrapper.page-home').classList.add(block.dataset.gsapChangeColor)
                    },
                    onEnterBack: () => {
                        if (!document.querySelector('.page-wrapper.page-home')) {
                            return;
                        }
                        // console.log(block, block.dataset.gsapChangeColor)
                        classes.forEach(className => {
                            document.querySelector('.page-wrapper.page-home').classList.remove(className)
                        })
                        document.querySelector('.page-wrapper.page-home').classList.add(block.dataset.gsapChangeColor)
                    },
                })
            })
        },
        initGsapGraph() {
            const graph = document.querySelector('[data-gsap-trigger-graph-init]')
            ScrollTrigger.create({
                trigger: graph,
                once: true,
                start: 'top 30%',
                onEnter: () => {
                    const graphs = gsap.utils.toArray('[data-gsap-trigger-graph-0]')
                    graphs.forEach(graph => {
                        const tl = gsap.timeline()
                        tl.to(graph, {
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            marginRight: 0,
                            opacity: 1,
                            delay: 0,
                            ease: 'power1.out'
                        })
                    })
                },
            })

            const reveals = gsap.utils.toArray('[data-gsap-reveal]')
            reveals.forEach(reveal => {
                ScrollTrigger.create({
                    trigger: reveal,
                    once: true,
                    start: 'top 70%',
                    onEnter: () => {
                        if (!document.querySelector('[data-gsap-reveal]')) {
                            return
                        }
                        const tl = gsap.timeline()
                        tl.to(reveal, {
                            y: '0%',
                            x: '0%',
                            opacity: 1,
                            delay: 0,
                            ease: 'power1.out'
                        })

                        const tl2 = gsap.timeline()
                        tl2.to(reveal.querySelector('.content'), {
                            y: '0%',
                            x: '0%',
                            opacity: 1,
                            delay: 0.75,
                            ease: 'power1.out'
                        })

                        const tl3 = gsap.timeline()
                        tl3.to(document.querySelector('.departement-item.item-center .content'), {
                            y: '0%',
                            x: '0%',
                            opacity: 1,
                            delay: 0.35,
                            ease: 'power1.out'
                        })

                        const tl4 = gsap.timeline()
                        tl4.to(document.querySelector('.departement-item.item-left .title'), {
                            opacity: 1,
                            delay: 0.75,
                            ease: 'power1.out'
                        })
                        const tl5 = gsap.timeline()
                        tl5.to(document.querySelector('.departement-item.item-center .title'), {
                            opacity: 1,
                            delay: 0.75,
                            ease: 'power1.out'
                        })
                        const tl6 = gsap.timeline()
                        tl6.to(document.querySelector('.departement-item.item-right .title'), {
                            opacity: 1,
                            delay: 0.75,
                            ease: 'power1.out'
                        })

                        const tl7 = gsap.timeline()
                        tl7.to(document.querySelector('.departement-item.item-left .regular-title'), {
                            y: 0,
                            x: 0,
                            delay: 0.75,
                            ease: 'power1.out'
                        })
                        const tl8 = gsap.timeline()
                        tl8.to(document.querySelector('.departement-item.item-center .regular-title'), {
                            y: 0,
                            x: 0,
                            delay: 0.75,
                            ease: 'power1.out'
                        })
                        const tl9 = gsap.timeline()
                        tl9.to(document.querySelector('.departement-item.item-right .regular-title'), {
                            y: 0,
                            x: 0,
                            delay: 0.75,
                            ease: 'power1.out'
                        })
                    },
                })
            })
        },
        gsapActivateFooter() {
            const footerNav = document.querySelector('[data-gsap-activate-footer]')
            const footerFold = document.querySelector('.footer-fold')

            ScrollTrigger.create({
                trigger: footerNav,
                start: 'bottom bottom',
                onEnter: () => {
                    if (!document.querySelector('.page-wrapper.page-home')) {
                        return;
                    }
                    footerFold.classList.add('is-active')
                },
                onLeaveBack: () => {
                    if (!document.querySelector('.page-wrapper.page-home')) {
                        return;
                    }
                    footerFold.classList.remove('is-active')
                },
            })
        },
        gsapScrollTo(selector) {
            gsap.to(window, {duration: 1, scrollTo: selector})
        }
    },
}
</script>
